/* eslint-disable max-len */
import * as React from 'react';

import { Icon } from '@app/image/icon';
import { RoutePaths } from '@app/utilities/RoutePathVars';
import { LinkType, link } from '@app/pages/StaticPages/utils';
import {
	ILeaderShip,
	IBrand,
	SiteKey,
	ICommunityOutreach,
	IAccolades,
	IPartners,
} from '@app/objects/AboutUsPage';

import {
	ovc,
	eco,
	enrich,
	expedia,
	luxury,
	dunhill,
	alliance,
	terracycle,
	kid,
	fb,
	feeding,
	surfrider,
	sweep,
	google,
	yelp,
	bbb,
} from '@image/aboutUsPage';

import {
	azamara,
	celeb,
	norwegian,
	oceania,
	princess,
	royal,
	viking,
} from '@image/brandsIcon';

export const getIntro = (
	toggleShowBurger?: (value: boolean) => void,
	toggleShowSubscribeForm?: (value: boolean) => void,
) => (
	<div>
		<p>
			Online Vacation Center Holdings Corp (ONVCH) has been in business for over 50 years, providing
			unprecedented cruise deals to savvy travelers. As one of the country's largest cruise retailers, we are able
			to negotiate exclusive rates and special extras you won't find anywhere else. Whether you're booking
			a cruise, tour or other vacation package, we promise that at the time of booking, our offer will beat or
			match the lowest available price advertised by competitors—backed by our {link(RoutePaths.whyOvc, LinkType.Internal, 'Low Price Guarantee')}.
		</p>
		<p>
			Frequent cruisers love the one-stop-shopping convenience of our
			<span className="font-weight_bold"> Value Inclusive Packages® (VIP) </span>
			that pair the best cruise values with airfare and hotel. Wherever you like to cruise, Online Vacation Center
			can take you there for less, backed by our <span className="font-weight_bold"> Low Price Guarantee</span>.
		</p>
		<p>
			If you want to be among the first to know about our best offers, <a onClick={() => { toggleShowBurger?.(true); toggleShowSubscribeForm?.(true); }}>sign up for our newsletter</a>, and have
			the hottest travel offers delivered directly to your inbox! You can also follow Vacation Manager
			on {link('https://twitter.com/VacationManager', LinkType.External, 'Twitter')} or like our {link('https://www.facebook.com/OnlineVacationCenter', LinkType.External, 'Facebook')} page for even more cruise specials and travel news!
		</p>
		<p>
			At Online Vacation Center, you don't have to sacrifice service to get a low price.
			Our dedicated team of Personal Vacation Managers® and Customer Service Managers will handle every aspect of your cruise vacation,
			helping to ensure you a fun and hassle-free travel experience. Your friendly and knowledgeable Personal
			Vacation Manager® will work closely with you to identify your cruise interests and preferences and will help
			you find the best sailing at the best price. Plan your next cruise vacation!
		</p>
		<p>
			Value, exclusive offers and service... that's what Online Vacation Center is all about!
		</p>
	</div>
);

// Leadership
const EDWARD = 'Mr. Rudner has more than 30 years of experience in the travel industry. Previously, he served as the CFO and COO of Alamo-Rent-A-Car. Under his guidance, Alamo-Rent-A-Car increased its fleet size from 400 to 50,000 automobiles, and expanded from a Florida-based company into a national car rental agency. Following his tenure at Alamo, Mr. Rudner went on to become president and CEO of Certified Vacations, where he cultivated the business from 10,000 to 250,000 vacation packages per year. More recently, he served as the CEO of Renaissance Cruises where he expanded ship assets from $60 million to over $1 billion and increased revenues from $20 million to $500 million over a nine-year period until June of 2000. In October of 2000, Mr. Rudner founded Online Vacation Center Inc., which today is one of the largest cruise sellers in the United States. Mr. Rudner graduated cum laude with a bachelor of arts degree in history from the University of Massachusetts and currently resides in Fort Lauderdale, Florida.';
const STEPHEN = 'Mr. Rudner has previously served as the Chief Financial Officer, Director of Information Technology, and Director of Sales of Online Vacation Center, Inc. Mr. Rudner joined the Company in November of 2011 as the Manager of Accounting. Prior to joining Online Vacation Center, Mr. Rudner worked as a senior auditor for Deloitte & Touche LLP. Mr. Rudner holds a Master of Science degree in Accounting from the University of Florida and is a Certified Public Accountant in the state of Florida.';
const KURT = 'R. Kurt Homfelt, President of Dunhill Vacations, Inc. joined Online Vacation Center Holdings Corp. from Miami, Florida based Club Med, most recently serving as its Director of National Sales. During his seven year tenure with the company, Mr. Homfelt was responsible for the creation and implementation of multiple nationwide sales and marketing initiatives as well as the development of their E-commerce platform. Mr. Homfelt also served as the Director of Travel and Entertainment Sales for First Data Corporation after spending 13 years at Certified Vacations, during which time he occupied a number of senior sales positions. Mr. Homfelt has a BS in Business Logistics from Pennsylvania State University.';

// Brands
const OVC = 'A leading U.S. cruise retailer, seller of all major cruise lines with exclusive rates and amenities backed by our Low Price Guarantee. Our Personal Vacation Managers® handle every step of a client\'s cruise vacation.';
const ENRICHMENT = 'A developer of one-of-a-kind, inclusive Journeys to compelling destinations pairing river and ocean cruises with curated Escorted Tours, Airfare, 4-Star+ Hotels & much more.';
const LUXURYLINK = 'An online window to inspiration and information about the world\'s best destinations, luxury accommodations, and curated travel experiences.';
const DUNHILL = 'A digital marketing company publicizing travel, vacation and cruise deals through an extensive email subscriber base and 40+ partner websites.';
const EXPEDIA = 'Expedia® Cruises of OVC® is a travel agency franchise supporting independent travel consultants who book air, land & sea vacations.';
export const COMMITMENT = 'Online Vacation Center Holdings and its employees care about the environment and the communities where we live, work, and travel. While ONVC has frequently been involved in charitable giving in past years, in 2019 we formalized our Corporate Social Responsibility Pledge committed to local charitable efforts and reducing our environmental impact.';
export const SOCIAL_RESPONSIBILITY = 'Since before Corporate Social Responsibility was a buzzword, we have been committed to the highest standards of corporate governance and community involvement. We are proud of our nearly five-decade legacy of ethical practices, sustainable practices, local involvement, customer service standards, and the dedication, loyalty and commitment of our employees. Together we strive for excellence in service and to be stewards of global citizenship.';
export const ETHICS = 'ONVC is committed to a work environment in which all individuals are treated with respect and dignity and are free from forms of harassment and discrimination. We are committed to doing the right thing and conducting ourselves in an ethical and trustworthy manner.';
export const ENVIRONMENTAL_IMPACT = (
	<>
		<p>
			ONVC is committed to the reduction of the environmental impact of our business through conservation and waste reduction practices. Our commitment to environmental protections begins in-house with our pledge to use environmentally friendly office supplies, reducing our waste and consumption, introducing recycling practices, energy conservation and better product sourcing including procuring supplies from Green vendors who are likewise committed to environmental sustainability practices.
		</p>
		<p>
			Our employees are committed as well and have formed a voluntary Eco-Committee that assesses the environmental impact of our purchasing decisions and spearheads environmental sustainability initiatives and beach clean-up efforts.
		</p>
	</>
);
export const COMMUNITY_OUTREACH = 'ONVC is committed to providing employees with opportunities to support charitable organizations through many means including volunteering, fundraising and donation drive efforts. Past efforts have included local beach clean-ups, food drives, toy drives and fundraisers.';
export const OUR_PEOPLE = 'ONVC commits to maintaining an inclusive and diverse workforce. We provide a professional, safe, positive and inspiring working environment. We accomplish this with having a diverse workforce at all levels, treating our employees with respect, recognition, and understanding. Our Code of Business Conduct guides employees to lead our business with integrity. We value service excellence, teamwork and relationship building with all fellow employees, vendors and clients.';
export const OUR_PARTNERS = 'ONVC partners with all major cruise lines and strives to offer you the best value, whether you book a cruise, Value Inclusive Package or Enrichment Journey.';
export const OUR_ACCOLADES = (
	<p>
		Online Vacation Center has an <span className="font-weight_bold">A+ rating from the Better Business Bureau</span> and is proud to hold high-producing agent status with every major cruise line. We were honored to be recognized as <span className="font-weight_bold">Azamara Cruises' Online Partner of the Year</span> in 2024 and <span className="font-weight_bold">Overall Account of the Year</span> in previous years. Celebrity Cruises has honored us a <span className="font-weight_bold">2024 Partner of the Year</span> and <span className="font-weight_bold">Top Strategic Partner of the Year</span> in 2018, 2017, 2016 and 2013. We have also been awarded as a top-producing <span className="font-weight_bold">Platinum Circle agency</span> with Viking River Cruises each year since 2010.
	</p>
);

export const leaderShips: Array<ILeaderShip> = [
	{
		name: 'Edward B. Rudner',
		grade: 'Director, Chairman, CEO of Online Vacation Center Holdings Corp',
		text: EDWARD,
	},
	{
		name: 'Stephen A. Rudner',
		grade: 'Director and President of Online Vacation Center Holdings Corp',
		text: STEPHEN,
	},
	{
		name: 'R. Kurt Homfelt',
		grade: 'President of Dunhill Vacations',
		text: KURT,
	},
];

const secondRowStyle = 'col-12 col-md-4';
export const brands: Array<IBrand> = [
	{
		logo: ovc,
		description: OVC,
		social: [
			{
				key: SiteKey.FB,
				link: 'https://www.facebook.com/OnlineVacationCenter',
			},
			{
				key: SiteKey.TW,
				link: 'https://twitter.com/VacationManager',
			},
			{
				key: SiteKey.BLOG,
				link: 'https://blog.onlinevacationcenter.com/',
			},
			{
				key: SiteKey.INST,
				link: 'https://www.instagram.com/onlinevacationcenter/',
			},
			{
				key: SiteKey.PIN,
				link: 'https://www.pinterest.com/vacationmanager/',
			},
		],
		className: 'col-12 col-md-6',
	},
	{
		logo: enrich,
		description: ENRICHMENT,
		social: [
			{
				key: SiteKey.FB,
				link: 'https://www.facebook.com/EnrichmentJourneys',
			},
			{
				key: SiteKey.TW,
				link: 'https://twitter.com/EnrichmentJourn',
			},
			{
				key: SiteKey.PIN,
				link: 'https://www.pinterest.com/enrichjourneys/',
			},
		],
		className: 'col-12 col-md-6',
	},
	{
		logo: luxury,
		description: LUXURYLINK,
		social: [
			{
				key: SiteKey.FB,
				link: 'https://www.facebook.com/LuxuryLink/',
			},
			{
				key: SiteKey.INST,
				link: 'https://www.instagram.com/luxurylink/',
			},
		],
		className: secondRowStyle,
	},
	{
		logo: dunhill,
		description: DUNHILL,
		social: [
			{
				key: SiteKey.FB,
				link: 'https://www.facebook.com/DunhillTravelDeals',
			},
			{
				key: SiteKey.TW,
				link: 'https://twitter.com/intent/follow?screen_name=DunhillDeals',
			},
			{
				key: SiteKey.INST,
				link: 'https://www.instagram.com/dunhilltraveldeals/',
			},
			{
				key: SiteKey.PIN,
				link: 'https://www.pinterest.com/dunhilltravel/',
			},
		],
		className: secondRowStyle,
	},
	{
		logo: expedia,
		description: EXPEDIA,
		social: [
			{
				key: SiteKey.SITE,
				link: 'https://www.expediacruises.com/en-US/900034',
			},
		],
		className: secondRowStyle,
	},
];

export const impactsIcon: Array<string> = [
	alliance,
	eco,
	terracycle,
];

export const communityOutreachList: Array<ICommunityOutreach> = [
	{
		icon: feeding,
		text: '1st-3rd Annual “Hunger Doesn\'t Vacation” Food Drive (2017-2019)',
	},
	{
		icon: kid,
		text: '1st-8th Annual Kids in Distress Toy Drive (2012-2019)',
	},
	{
		icon: sweep,
		text: 'Beach Clean-Up-April 2019',
	},
	{
		icon: surfrider,
		text: 'Beach Clean-Up-June 2019',
	},
];

const linkText: React.ReactNode = <p className="text-size_xx-small">View Reviews</p>;
export const accoladesList: Array<IAccolades> = [
	{
		icon: bbb,
		link: 'https://www.bbb.org/us/fl/fort-lauderdale/profile/travel-agency/online-vacation-center-0633-4007712#bbbseal?rf=dc&cid=1',
		text: (
			<p>
				<div className="font-weight_semi-bold">BBB Rating: A+</div>
				<div className="text-size_xx-small">As of 4/13/2023</div>
				<div className="text-size_xx-small">Click for Profile</div>
			</p>
		),
		style: {
			display: 'flex',
			flexDirection: 'row',
		},
	},
	{
		icon: google,
		link: 'https://www.google.com/search?q=online%20vacation%20center',
		text: linkText,
	},
	{
		icon: fb,
		link: 'https://www.facebook.com/OnlineVacationCenter/about',
		text: linkText,
	},
	{
		icon: yelp,
		link: 'https://www.yelp.com/biz/online-vacation-center-fort-lauderdale',
		text: linkText,
	},
];
export const partners: Array<IPartners> = [
	{
		link: 'https://www.azamara.com/',
		icon: azamara,
	},
	{
		link: 'https://www.celebritycruises.com/',
		icon: celeb,
	},
	{
		link: 'https://www.vikingcruises.com/?agenturlid=ovc',
		icon: viking,
	},
	{
		link: 'https://www.ncl.com/',
		icon: norwegian,
	},
	{
		link: 'https://www.oceaniacruises.com/',
		icon: oceania,
	},
	{
		link: 'https://www.princess.com/',
		icon: princess,
	},
	{
		link: 'https://www.royalcaribbean.com/',
		icon: royal,
	},
];

export function getIconSite(key: SiteKey) {
	switch (key) {
	case SiteKey.FB:
		return <Icon.Facebook />;
	case SiteKey.TW:
		return <Icon.Twitter />;
	case SiteKey.PIN:
		return <Icon.Pinterest />;
	case SiteKey.INST:
		return <Icon.Instagram />;
	case SiteKey.BLOG:
		return <Icon.Blog />;

	default:
		return <Icon.Globe />;
	}
}
